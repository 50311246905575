import { VBaseService, VServices } from "@libTs/vue-base";
import axios from "axios";
import { API_BASE_URL, CRAFT_LANGUAGE, SITEURL } from '@libTs/cms.globals';


export class apiService extends VBaseService {
  public template = '&template=ET2014A_LIGHT.json';
  public templateDetail = '&template=ET2014A.json';
  public templateMap = '&template=geojson';
  public templateGpx = '&template=gpx';
  public urlPresetConfig = '?experience=wiege-der-schweiz&licensekey=61D47441';

  public getData( config: Record<string, string> ) {
    let params = '';

    if(CRAFT_LANGUAGE === 'fr-CH') {
      config['mkt'] = 'fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      config['mkt'] = 'en';
    }

    for ( const key in config ) {
      const item = config[ key ];

      if ( item !== null ) {
        params += `&${key}=${item}`;
      }
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.template}`;

    return axios
      .get( url );
  }

  public getDetailData( id: string ) {
    let params = '';

    params += `&q=id:${id}`;

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateDetail}`;

    return axios
      .get( url );
  }

  public getMapData(id: string) {
    let params = '';

    params += `&q=id:${id}`;

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateMap}`;

    return axios
      .get( url );
  }

  public getKeywordMapData(keyword: string) {
    let params = '';

    params += `&q=keyword:${keyword}`;

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateMap}`;

    return axios
      .get( url );
  }


  public getGpxData(id: string) {
    let params = '';

    params += `&q=id:${id}`;

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateGpx}`;

    return axios
      .get( url );
  }

  public getGpxUrl(id: string) {
    let params = '';

    params += `&q=id:${id}`;

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateGpx}`;

    return url;
  }

  public getSchulangebotPOIs() {
    let params = '';

    params += '&type=POI&q=keyword:wdsk_WdS_Schulen_POI';

    if(CRAFT_LANGUAGE === 'fr-CH') {
      params += '&mkt=fr-FR';
    }
    if(CRAFT_LANGUAGE === 'en-CH') {
      params += '&mkt=en';
    }

    const url = `${API_BASE_URL}${this.urlPresetConfig}${params}${this.templateDetail}`;

    return axios
    .get( url );
  }

  public refreshAPICache() {
    const url = `${SITEURL}actions/wds/items/save-items`;

    axios.get(url);
  }
}


